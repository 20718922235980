import { useEffect } from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { rgba } from 'polished';
import { SWRConfig } from 'swr';
import localFont from 'next/font/local';

import AOS from 'aos';

import theme from '@/components/theme';

import logo from '@root/public/img/logo.png';

import 'aos/dist/aos.css';

const agenda = localFont({
  src: [
    {
      path: 'fonts/Agenda/AgendaRegular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: 'fonts/Agenda/AgendaMedium.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: 'fonts/Agenda/AgendaSemibold.woff2',
      weight: '600',
      style: 'normal',
    },
    {
      path: 'fonts/Agenda/AgendaBold.woff2',
      weight: '700',
      style: 'normal',
    },
    {
      path: 'fonts/Agenda/AgendaBlack.woff2',
      weight: '900',
      style: 'normal',
    },
  ]
});

const GlobalStyle = createGlobalStyle`
  :root {
    --font-agenda: ${agenda.style.fontFamily};
  }

  ::selection {
    background: ${props => rgba(props.theme.colors.secondary, 0.25)};
    color: ${props => props.theme.colors.main};
  }

  ::-webkit-selection {
    background: ${props => rgba(props.theme.colors.secondary, 0.25)};
    color: ${props => props.theme.colors.main};
  }

  ::-moz-selection {
    background: ${props => rgba(props.theme.colors.secondary, 0.25)};
    color: ${props => props.theme.colors.main};
  }

  html,
  body {
    min-height: stretch;
    margin: 0;
    padding: 0;
    font-family: var(--font-agenda);
    font-size: 16px;
    font-weight: 500;
    background-color: ${props => props.theme.colors.bg};
    color: ${props => props.theme.colors.main};

    @media screen and (min-width: 1201px) and (max-width: 1920px) {
      font-size: ${props => props.theme.helpers.toVw(16)};
    }

    @media screen and (max-width: 960px) {
      font-size: 14px;
    }

    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }

  body {
    margin: 0 auto;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.secondary};
    transition: all 0.2s;

    &:focus,
    &:hover {
      color: ${props => props.theme.colors.main};
    }
  }

  svg {
    fill: currentColor;
  }

  code {
    padding: ${props => props.theme.helpers.toRem(3)} ${props => props.theme.helpers.toRem(6)};
    border-radius: 10px;
    font-size: 0.9em;
    font-family: IBM Plex Mono, monospace;
    background: ${props => rgba(props.theme.colors.mainDark, 0.1)};
  }

  blockquote {
    min-height: ${props => props.theme.helpers.toRem(50)};
    margin-left: 0;
    margin-right: 0;
    padding: 0.8em 4em 0.8em 1em;
    position: relative;
    border-width: ${props => props.theme.helpers.toRem(1)} ${props => props.theme.helpers.toRem(1)} ${props => props.theme.helpers.toRem(1)} ${props => props.theme.helpers.toRem(4)};
    border-style: solid;
    border-color: ${props => rgba(props.theme.colors.main, 0.1)};
    border-radius: ${props => props.theme.helpers.toRem(10)};
    background: ${props => rgba(props.theme.colors.main, 0.02)};
    overflow: hidden;

    &:after {
      content: '”';
      position: absolute;
      top: ${props => props.theme.helpers.toRem(-10)};
      right: ${props => props.theme.helpers.toRem(10)};
      font-size: ${props => props.theme.helpers.toRem(90)};
      opacity: 0.3;
    }
  }

  #__next {
    min-height: stretch;
    display: flex;
    flex-direction: column;
  }
`;

export default function App({ Component, pageProps }: AppProps) {
  const fetcher = (...args: Parameters<typeof fetch>) => fetch(...args).then(res => {
    if (res.status >= 400 && res.status < 600) throw new Error(res.statusText);
    return res.json();
  }).catch(err => { throw new Error(err); });

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <SWRConfig value={{ fetcher }}>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover" />

          <meta property="og:title" content="Léo Magalhães" key="og-title" />
          <meta property="og:description" content="Coragem, determinação, talento e carisma, elementos que se encaixaram na vida de um garoto chamando Léo Magalhães." key="og-description" />
          <meta property="og:image" content={logo.src} key="og-image" />
        </Head>

        <main>
          <Component {...pageProps} />
        </main>

        <GlobalStyle />
      </SWRConfig>
    </ThemeProvider>
  );
}
